$mq-medium: 680px;
$mq-large: 960px;
$mq-xl: 1200px;
$mq-xxl: 1440px;

@mixin cap-text {
  font-family: var(--font-family-accent);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.025em;
  font-size: .85em;
}