@import "sass-variables.scss";
.header {
  background-image: url("../img/header-large-bg.svg");
  background-size: cover;
  background-position: bottom center;

  position: sticky;
  top: -90px;
  margin-bottom: -4px;
  z-index: 400;

  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .15));
}

// PREHEADER

.preheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  max-width: 970px !important;

  gap: 20px;
  font-family: var(--font-family-accent);
  color: var(--color-primary-subtle);
  font-size: 1.2rem;

  background: radial-gradient(ellipse, rgba(153, 211, 255, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
  background-position: 50% 14px;
  background-size: 110% 100%;
  background-repeat: no-repeat;

  overflow: hidden;

  padding-inline: 15px !important;

  @media (max-width: ($mq-medium - 1px)) {
    justify-content: center;
    // background-color: var(--color-alt-tint-light);
  }
}

.secondaryNav {
  display: flex;
  gap: 30px;
  font-weight: var(--font-weight-accent-bold);

  @media (max-width: ($mq-medium - 1px)) {
    display: none;
  }

  a:hover {
    color: var(--color-primary)
  }
}
.externalLink {
  display: flex;
  align-items: center;
  gap: 4px;
}

.covidNotice {
  &__title {
    font-size: .95em;
    font-weight: var(--font-weight-accent-bold);
    &::after {
      content: "";
      border-right: 1px solid currentColor;
      opacity: .4;
      margin-inline: .5em;
    }
  }
}

