@import "sass-variables.scss";
.wrapper {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.1rem 1.4rem; // matched with navbar items

  cursor: pointer;

  .iconWrapper {
    color: var(--color-primary-subtle);
    padding: .15em .3em;
    background-color: var(--color-alt-tint-light);
    border-radius: 15%;
    transition: background-color .2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    transition: color .2s;
    circle {
      transition: transform .2s ease;
    }
  }

  &.active, &:hover {
    .iconWrapper {
      color: var(--color-primary);
      background-color: #deeefa;
    }

    svg {
      circle:first-child {
        transform: translateX(-4%);
      }
      circle:last-child {
        transform: translateX(4%);
      }
    }
  }
}

.dropdownList {
  position: absolute;
  z-index: 10;
  background: #FEFEFF;
  left: 0;
  top: calc(100% + .2rem);
  padding-block: .5em 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 12px 8px -4px rgba(0,0,0,.15);

  a {
    border-radius: 0 !important;
    border-top: 1px solid #f0f0f0;
    padding-block: 1.5rem !important;
    min-width: 18ch;
  }
}