.container {
  max-width: var(--container-max-width);
  margin-inline: auto;
  padding-inline: var(--container-padding-inline);
}

.pageContainer {
  max-width: 800px;
  margin-inline: auto;
  padding-block: 80px 100px;
  padding-inline: var(--container-padding-inline);
}