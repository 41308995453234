@import "sass-variables.scss";
.ctaWrapper {
  top: 0;
  text-align: center;
  font-family: var(--font-family-accent);
  color: var(--color-primary-subtle);
  padding-bottom: 2px;
}

.ctaContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
  height: 56px;
  padding-inline: 30px;

  @media(min-width: ($mq-medium)) and (max-width: ($mq-large - 1px)) {
    padding-inline: 80px;
  }
}

.companyLogoIcon {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  @media(max-width: ($mq-medium - 1px)) {
    display: none;
  }

  @media(min-width: $mq-medium) {
    left: 30px;
  }
}

.ctaItem {
  width: 190px;

  @media (min-width: $mq-medium) {
    width: 280px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  background: radial-gradient(ellipse, rgba(153, 211, 255, 0.7) 0%, rgba(0, 0, 0, 0) 60%);
  background-position: 50% -42px;
  background-size: 120% 100%;
  background-repeat: no-repeat;

  transition: background .3s;

  @media (hover: hover) {
    &:hover {
      background-position: 50% -34px;
      background-size: 140% 100%;

      .ctaItem__title {
        color: var(--color-primary);
      }
    }
  }

  &__mc {
    font-size: 1.15rem;
  }

  &__title {
    line-height: 1.2;
    font-size: 1.65rem;
    transition: color .3s;
  }
}

.showroomItem {
  @media (max-width: 599px) {
    display: none;
  }
}