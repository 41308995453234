.button {
  --button-on-dark-hsl: 206, 100%, 63%; // --color-alt-tint

  min-width: 100px;
  max-width: 340px;

  padding: .9em 2em;

  text-transform: uppercase;
  font-family: var(--font-family-accent);
  font-weight: var(--font-weight-accent-bold);
  text-shadow: 0 2px 0.5px rgba(0, 0, 0, .06);
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
  text-align: center;

  transition: background .2s ease-out;

  border-radius: 4px;

  background: linear-gradient(to bottom, var(--color-primary) 0%, var(--color-primary-darker) 100%);
  color: #fff;

  display: inline-block;

  &:hover {
    background: linear-gradient(to bottom, var(--color-primary) 0%, var(--color-primary) 100%);
  }

  &--small {
    font-size: 1.2rem;
  }

  &--accent {
    background: linear-gradient(180deg, var(--color-alt) 0%, #2097F1 100%);

    border: 1px solid #40ADFF;
    box-shadow: 0 2px 4px -2px rgba(0,0,0,.2);

    &:hover {
      background: linear-gradient(180deg, var(--color-alt) 0%, var(--color-alt) 100%);
    }
  }

  &--onDark {
    color: #fff;
    background: hsla(var(--button-on-dark-hsl), .2);
    border: 1px solid hsla(var(--button-on-dark-hsl), .02);

    &:hover {
      background: hsla(var(--button-on-dark-hsl), .25);
    }
  }

  &--outline {
    color: var(--color-alt-tint);
    background: rgba(15, 16, 20, 0.5);
    border: 1px solid rgba(153, 211, 255, 0.6);

    &:hover {
      background: rgba(15, 16, 20, 0.55);
    }
  }

  &--expand {
    display: block;
  }
}