@import "sass-variables.scss";
.wholeHomeContainer {
  text-align: center;
  padding-block: 60px 65px;
  padding-inline: var(--container-padding-inline);
  border-top: 1px solid var(--color-alt-tint-light);
  border-bottom: 1px solid rgba(255,255,255,.5);
  background: linear-gradient(to bottom, #fff, var(--color-alt-tint-light) 150px);

  h5 {
    color: var(--color-primary-darker);
  }
}

.logosContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 12px 30px;
  margin-top: 17px;

  a {
    font-family: var(--font-family-accent);
    color: var(--color-primary-darker);
    font-size: 1.25rem;

    strong {
      font-weight: var(--font-weight-accent-bold);
      text-decoration: underline;
    }
  }
}

.footerWrapper {
  background: var(--color-alt-tint-light);
  padding-block: 40px;
  color: var(--color-primary-darker);
  font-family: var(--font-family-accent);

  >* {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
}

.showroomInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 30px;

  font-size: 1.4rem;
  line-height: 1.4;

  h4 {
    font-weight: var(--font-weight-accent-bold);
  }
}

.address, .hours {
  >* {
    white-space: nowrap;
  }
}

.contactDetails {
  margin-top: 24px;
  font-size: 2rem;
  font-family: var(--font-family-accent);

  @media (min-width: $mq-large) {
    text-align: right;
  }
}

.telephone {
  strong {
    font-weight: var(--font-weight-accent-bold);
  }
  a {
    white-space: nowrap;
  }
}

.contactOnline {
  font-size: .95em;
  margin-top: .7em;
  text-decoration: underline;
  text-underline-offset: .1em;
  text-decoration-color: var(--color-neutral);
  text-decoration-thickness: .06em;

}

.legalContainer {
  font-size: 1.2rem;
  margin-top: 30px;
  align-items: flex-end;
  line-height: 1.5;

  nav {
    >*:not(:last-child)::after {
        content: "";
        border-right: 1px solid currentColor;
        margin-inline: .9em;
        display: inline-block;
        height: .8em;
        opacity: .4;
      }
  }
}

.credit {
  opacity: .7;
  transition: color .2s;
  font-size: 1.2rem;

  &:hover {
    opacity: 1;
  }
}