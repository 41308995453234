@import "sass-variables.scss";
.overlay {
  position: fixed;
  background-color: rgba(0,0,0,.3);
  inset: 0;
  z-index: 1000;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(to top left, var(--color-alt-tint-light) 50%, #fff);
  max-width: 75vw;
  width: 350px;
  min-width: 200px;

  padding: 40px 20px;

  box-shadow: 6px 0 1px rgba(0,0,0,.01);

  overflow: scroll;

  nav li {
    margin-bottom: 4px;
    display: block;
    font-family: var(--font-family-accent);
    font-weight: var(--font-weight-accent-bold);

    color: var(--color-primary);

    >a {
      display: inline-block;
      min-width: 160px;
      padding: 12px 20px;
    }
  }
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 10px;
  height: 40px;
  width: 40px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 8px;

  &:hover {
    background-color: #fff;
    border: 1px solid var(--color-alt-tint-light);
  }
}