@font-face {
  font-family: "aktiv-grotesk";
  src: url("../fonts/aktiv-grotesk_400_normal.woff") format("woff"), url("../fonts/aktiv-grotesk_400_normal.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "aktiv-grotesk-extended";
  src: url("../fonts/aktiv-grotesk-extended_400_normal.woff") format("woff"), url("../fonts/aktiv-grotesk-extended_400_normal.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "aktiv-grotesk-extended";
  src: url("../fonts/aktiv-grotesk-extended_700_normal.woff") format("woff"), url("../fonts/aktiv-grotesk-extended_700_normal.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}

:root {
  --font-family-primary: aktiv-grotesk, sans-serif;
  --font-family-accent: aktiv-grotesk-extended, sans-serif;

  --font-weight-primary-normal: 400;

  --font-weight-accent-normal: 400;
  --font-weight-accent-bold: 700;
}

html {
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

body {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-normal);
  color: var(--color-text-on-light);

  font-size: 1.6rem;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: var(--font-family-accent);
  font-weight: var(--font-weight-accent-normal);
  line-height: 1.3;
  &:not(:last-child) { margin-bottom: .5em;}

  strong {
    font-weight: var(--font-weight-accent-bold);
  }
}

.h1 {
  color: var(--color-primary);
  font-size: 2.9rem;
  @media (min-width: $mq-medium) {
    font-size: 3.2rem;
  }
}

.h2 {
  font-size: 2.6rem;
  @media (min-width: $mq-medium) {
    font-size: 2.8rem;
  }
}

.h3 {
  font-size: 2.4rem;
  @media (min-width: $mq-medium) {
    font-size: 2.6rem;
  }
}

.h4 {
  font-size: 2.2rem;
  @media (min-width: $mq-medium) {
    font-size: 2.4rem;
  }
}

.h5 {
  font-size: 1.8rem;
  @media (min-width: $mq-medium) {
    font-size: 2rem;
  }
}

p {
  font-size: 1.5rem;
  @media (min-width: $mq-medium) {
    font-size: 1.6rem;
  }
  line-height: 1.7;
  max-width: 90ch;
  color: --color-text-on-light;

  & + & { margin-top: 1em; }

  strong {
    // font-weight: var(--font-weight-primary-bold);
    text-shadow: 0 .5px 1px currentColor;
  }

  a {
    color: var(--color-primary);
    text-decoration: underline;
    text-decoration-color: var(--color-alt-tint);

    &:hover {
      color: var(--color-alt);
    }
  }
}

.match {
  color: inherit;
}

.capText {
  @include cap-text;
}

ul.list {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 1em;
  li {
    padding-left: .5em;
    list-style:disc;

    & + li {
      margin-top: .5em;
    }
  }
}