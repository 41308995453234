@import "sass-variables.scss";
.navBar {
  max-width: 1080px;
  margin-inline: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
  @media (min-width: $mq-medium) {
    gap: 14px;
  }

  padding: 8px 10px;

  background: #FEFEFF;
  box-shadow: 0 5px 9px -11px rgba(21, 41, 125, 0.90);

  &__left, &__right {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $mq-large) {
    &__left {
      justify-content: flex-end;
    }
    &__right {
      justify-content: flex-start;
    }
  }
}


.logoWrapper {
  flex: 0 0 auto;

  img { object-fit: contain; }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    @media (max-width: ($mq-medium - 1px)) {
      max-width: 200px !important;
    }
  }
}

.navGroup {
  font-family: var(--font-family-accent);
  font-weight: 700;
  font-size: 1.3rem;

  display: flex;
  gap: 4px;

  @media (max-width: ($mq-large - 1px)) {
    display: none;
  }

  li {
    color: var(--color-primary);
    white-space: nowrap;

    a {
      display: block;
      padding: 1.1rem 1.4rem;
      border-radius: 4px;

      &:hover {
        color: var(--color-primary-darker);
      }
    }
  }
}

.social {
  display: flex;
  color: var(--color-primary-subtle);
  gap: 20px;
  align-items: center;

  a, svg { display: block; }

  a:hover { color: var(--color-primary); }

  @media (min-width: $mq-large) {
    &::before {
      content: "";
      height: 20px;
      border-left: 1px solid currentColor;
      margin-inline: 10px;
      opacity: .4;
    }
  }
}

.navButton {
  @media (min-width: $mq-large) {
    display: none;
  }

  align-self: stretch;
  color: var(--color-primary);

  display: flex;
  align-items: center;
  gap: 8px;

  text-transform: uppercase;
  font-family: var(--font-family-accent);
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.1em;

  // increase tap target size
  height: 40px;
  padding-inline: 8px;

  cursor: pointer;

  &:hover {
    color: var(--color-primary-darker);
  }
}