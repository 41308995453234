@import "sass-variables.scss";
:root {
  // COLOURS
  --color-alt: #40ADFF;
  --color-alt-tint: #99D3FF;
  --color-alt-tint-light: #EBF6FF;
  --color-dark: #1D2B59;
  --color-darkest: #0F1014;
  --color-neutral-tint: #86868C;
  --color-primary: #2F4AA3;
  --color-primary-darker: #15297D;
  --color-primary-subtle: #4C5C9E;
  --color-text-on-light: #2F3034;

  // SPACING
  --container-padding-inline: 20px;
  --container-max-width: #{$mq-xxl};

  @media (min-width: $mq-medium) {
    --container-padding-inline: 30px;
  }
  @media (min-width: $mq-large) {
    --container-padding-inline: 50px;
  }

  --full-height-hero: calc(100vh - 144px); // header height + overlap
}

@import 'typography';
@import 'layout';
@import 'buttons';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

.grecaptcha-badge {
  visibility: hidden;
}